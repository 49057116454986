<div>
  <div class="w-100 close-button-container">
    <!-- <button  mat-icon-button (click)="onNoClick()"><mat-icon>close</mat-icon></button> -->
  </div>
  <h2 mat-dialog-title>{{'login.recoveryAddress' | translate}}</h2>
  <mat-dialog-content class="mat-typography">
    <form>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{'shared.email' | translate}}</mat-label>
        <input matInput
              type="email"
              [formControl]="myControl"
              [autofocus]="false">
      </mat-form-field>
      <div *ngIf="myControl.touched && myControl.invalid" class="alert alert-danger">
        <div *ngIf="myControl.errors?.required">{{'shared.emailErrorRequired' | translate}}</div>
        <div *ngIf="myControl.errors?.email">{{'shared.emailErroInvalid' | translate}}</div>
    </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="button-add success-button" mat-button (click)="submit()">{{'shared.submit' | translate}}</button>
  </mat-dialog-actions>
</div>
