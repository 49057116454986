import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from './shared/services/permission.guard';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./views/jobs/jobs.module')
      .then(m => m.JobsModule)
  },
  {
    path: 'joblogin', loadChildren: () => import('./views/login/login.module')
      .then(m => m.LoginModule)
  },
  {
    path: 'joblogin/:jobOfferId', loadChildren: () => import('./views/login/login.module')
      .then(m => m.LoginModule)
  },
   {
    path: 'reg',
    canActivate: [PermissionGuard],
    loadChildren: () => import('./views/registration/registration.module')
      .then(m => m.RegistrationModule)
  },
   {
    path: 'passwordReset', loadChildren: () => import('./views/login/password-reset/password-reset.component')
      .then(m => m.PasswordResetComponent)
   },
   {
    path: 'registration', loadChildren: () => import('./views/questions-registration/questions-registration.module')
      .then(m => m.QuestionsRegistrationModule)
  },
   {
     path: 'jobregistration/:jobOfferId', loadChildren: () => import('./views/questions-registration/questions-registration.module')
      .then(m => m.QuestionsRegistrationModule)
  },
   {
    path: 'success-registration', loadChildren: () => import('./views/success-questions/success-questions.module')
       .then(m => m.SuccessQuestionsModule)
  },
  {
    path: 'job-offer-detail/:id', loadChildren: () => import('./views/job-detail/job-detail.module')
      .then(m => m.JobDetailModule)
  },
  {
    path: 'contact-form', loadChildren: () => import('./views/contact-form/contact-form.module')
      .then(m => m.ContactFormModule)
  },
  {
    path: 'contact-form/:jobOfferId', loadChildren: () => import('./views/contact-form/contact-form.module')
      .then(m => m.ContactFormModule)
  },
  {
    path: 'error/:errorType', loadChildren: () => import('./views/error-page/error-page.module')
      .then(m => m.ErrorPageModule)
  },
  {
    path: '**', loadChildren: () => import('./views/error-page/error-page.module')
      .then(m => m.ErrorPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
