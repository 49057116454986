<div class="background-img d-flex flex-center flex-column flex-column-fluid">
  <mat-card class="container login-container">
    <form [formGroup]="loginForm">
      <h2 *ngIf="reactionOnJobOffer">{{'login.headerFromJobOffer' | translate}}</h2>
      <h2 *ngIf="!reactionOnJobOffer">
        <a href="">
          <img src="../../../assets/img/tw2.png"  class="w-75">
        </a>
      </h2>
      <h3 *ngIf="!reactionOnJobOffer">{{'login.newHereUser' | translate}} <a routerLink="/registration">{{'login.singUp' | translate}}</a></h3>
      <mat-divider></mat-divider>
      <div class="d-flex justify-content-between option-menu">
        <div class="d-flex flex-column w-100  mt-3"  [ngClass]="reactionOnJobOffer ? 'add-margin' : ''">
          <h2 *ngIf="reactionOnJobOffer">{{'login.title' | translate}}</h2>
          <div class="d-flex flex-column">
            <mat-form-field appearance="outline">
              <mat-label>{{'stepperForm.personalInfo.email' | translate}}</mat-label>
              <input matInput placeholder="ex. example@gmail.com" formControlName="email" (keydown.enter)="reactionOnJobOffer? joblogin(): login()" autocomplete="email">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{'shared.password' | translate}}</mat-label>
              <input matInput placeholder="Placeholder" formControlName="password" type="password" (keydown.enter)="reactionOnJobOffer? joblogin(): login()" autocomplete="current-password">
            </mat-form-field>
            <button *ngIf="!reactionOnJobOffer" mat-raised-button class="success-button" (click)="login()">{{'login.logIn' | translate}}</button>
            <button *ngIf="reactionOnJobOffer" mat-raised-button class="success-button" (click)="joblogin()">{{'login.logIn' | translate}} jobs</button>
            <p class="mt-3 text-center"><a (click)="forgotPassword()">{{'login.forgotPassword' | translate}}</a></p>
          </div>
        </div>
        <div *ngIf="reactionOnJobOffer" class="w-100 mt-3 d-flex flex-column">
          <h2>{{'login.titleRegister' | translate}}</h2>
          <div class="d-flex flex-column center-class">
            <button (click)="jobregister()" mat-raised-button type="button" class="success-button">{{'login.titleRegister' | translate}} jobs</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>
