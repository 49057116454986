import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserFromBE } from '@talentway/shared';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
  ){
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let user: UserFromBE;

    this.auth.currentUser$.subscribe(res =>  user = res);
    if (!user) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    const hasRolePermission = user ? true : false;

    if (!hasRolePermission) {
      this.router.navigate(
        ['/joblogin'],
        { queryParams: { redirect: 'info_request' }}
      );
    }
    return hasRolePermission;
  }

}
