import { CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  HostListener,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { Directionality } from '@angular/cdk/bidi';
import { AuthService } from 'src/app/shared/services/auth.service';


import { NavigationToggleService } from 'src/app/shared/services/navigation-toggle.service';
import { UserFromBE } from '@talentway/shared';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [
    {provide: CdkStepper, useExisting: StepperComponent}
  ],
})
export class StepperComponent extends CdkStepper implements OnInit, OnDestroy {

  constructor(dir: Directionality,
              changeDetectorRef: ChangeDetectorRef,
              elRef: ElementRef,
              private navService: NavigationToggleService,
              private cdr: ChangeDetectorRef,
              private authService: AuthService,
              private router: Router,
              public activatedRoute: ActivatedRoute,
              ) {
    super(dir, changeDetectorRef, elRef);

  }

  showNav = false;
  message?: string;
  user: UserFromBE;
  userName: string;
  subscription!: Subscription;
  public screenWidth = window.innerWidth;
  public screenHeight: any;


  @Input() activeClass = 'active';
  @ViewChild('drawer') public drawer!: MatDrawer;

  @HostListener('window:resize', ['$event'])

  onResize(event: any): void {
    if (event.target.innerWidth < 800) {
      this.screenWidth = window.innerWidth;
    }
    if (event.target.innerWidth > 800) {
      this.screenWidth = window.innerWidth;
    }
  }

  ngOnInit(): void {
    this.navService.currentNavToggle.subscribe(test => {
      this.showNav = test;
      this.cdr.detectChanges();
    });
    this.user = JSON.parse(localStorage.getItem('user'));
    if(this.user){
      this.userName = this.user.firstName + ' ' + this.user.lastName;
    }
  }

  logout(): void {
    this.authService.signOut(this.user.id).subscribe(res => {
      this.router.navigate(['/joblogin'], {
        queryParams: {
          'redirect': null,
        },
        queryParamsHandling: 'merge'
      });
      localStorage.removeItem('user');
      localStorage.removeItem('session');
      this.user = undefined;
    });
  }


  login(): void {
    this.router.navigate(['joblogin']);
  }


  toJobs(): void {
    this.router.navigate(['']);
  }

  // tslint:disable-next-line:typedef
  isNextButtonHidden() {
    return !(this.steps.length === this.selectedIndex + 1);
  }

}
