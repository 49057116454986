<section class="container h-100">


  <mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav h-100" mode="side"  [opened]="(screenWidth <= 800  ? false: true) || showNav">
      <div class="d-flex align-items-center justify-content-around logo">
        <img  src="./../../../assets/img/tw2.png">
      </div>
      <header class="header mb-auto">
        <ol>
          <ng-container *ngFor="let step of steps; let i = index;">
            <li (click)="selectedIndex = i" class="cursor-pointer" [ngClass]="{'active': selectedIndex === i}">
              <a class="d-flex align-items-center a-part" [ngClass]="{'active': selectedIndex === i}">
                <ng-container *ngIf="step.stepLabel; else showLabelText" [ngTemplateOutlet]="step.stepLabel.template" [ngTemplateOutletContext] ="{value: i, selectedI: selectedIndex}">
                </ng-container>
                <ng-template #showLabelText>
                  {{ step.label }}
                </ng-template>
              </a>
            </li>
          </ng-container>
        </ol>
      </header>
      <div class="mt-auto d-flex flex-column align-items-center mb-3" *ngIf="user">
        <mat-divider class="w-100 mb-2"></mat-divider>
        <div class="w-75 d-flex flex-row justify-content-evenly">
          <mat-icon>person</mat-icon>
          <span>{{ userName }}</span>
        </div>
      </div>

    </mat-drawer>

    <div class="example-sidenav-content">

      <div class="body" *ngIf="selected">
        <div class="d-flex flex-row justify-content-end" [ngStyle]="screenWidth >= 801? {'padding-bottom.rem': 3} : {'padding-bottom.rem': 0}" >
          <button mat-raised-button class="m-2" (click)="toJobs()" mat-menu-item>
            <span>{{'jobsPage.content.title' | translate}}</span>
          </button>

          <div *ngIf="user">
            <button mat-raised-button class="m-2" (click)="logout()" mat-menu-item>
              <span>{{'shared.logout' | translate}}</span>
            </button>
          </div>

          <div *ngIf="!user">
            <button mat-raised-button class="m-2" (click)="login()" mat-menu-item>
              <span>{{'shared.logIn' | translate}}</span>
            </button>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
      </div>
    </div>
  </mat-drawer-container>
</section>
