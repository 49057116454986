<div class="d-flex justify-content-center">
    <input type="file" class="file-input"
        (change)="addedNewFileToUpload($event)" #fileUpload>
    <div class="d-flex w-75 justify-content-around align-items-center file-upload mb-4">
        <div class="file-exist" *ngIf="form.get('attachmentID').value > 0">

            <p (click)="downloadAttachment()" class="m-0">
                <mat-icon class="upload-file-btn dot cursor-pointer">download</mat-icon>
            </p>
            <p>{{ form.get('candidateAttachment.fileName')?.value}}</p>
        </div>

        <div class="d-flex align-items-center">
            <p *ngIf="!(form.get('attachmentID').value > 0)">{{'stepperForm.fileUpload' | translate}}</p>
            <a mat-mini-fab class="upload-file-btn dot cursor-pointer"
            (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
            </a>
        </div>
    </div>
</div>