import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { PeriodTranslatePipe } from '../pipes/period-translate';


@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [
    DateAgoPipe,
    PeriodTranslatePipe
  ],
  exports: [
    CommonModule, FormsModule, TranslateModule, DateAgoPipe, PeriodTranslatePipe],
  providers: [TranslateService]
})
export class SharedModule {
}
