import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { TranslateLanguageService } from './shared/services/tanslate-language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'talentway-fe';

  constructor(
    public translateService: TranslateService,
    public translateLang: TranslateLanguageService
    ) {
    translateService.addLangs(['en', 'sk', 'cz']);
    if (localStorage.getItem('language')) {
      translateLang.languageValue.subscribe((nextValue) => {
      translateService.use(nextValue);
      });
    } else {
      translateLang.language = 'en';
    }
  }

  translateSite(langauge: string): void {
    this.translateService.use(langauge);
  }
}
