import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration.component';
import {MatStepperModule} from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { StepperModule } from '../stepper/stepper.module';
import { NavigationToggleService } from 'src/app/shared/services/navigation-toggle.service';



@NgModule({
  declarations: [
    RegistrationComponent,
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    CdkStepperModule,
    StepperModule,
    RouterModule.forChild(
      [
        {
          path: '',
          component: RegistrationComponent
        }
      ]
    ),
  ],
  providers: [NavigationToggleService],
  exports: [RegistrationComponent]
})
export class RegistrationModule { }
