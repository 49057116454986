import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavigationToggleService {

  private navToggleSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentNavToggle = this.navToggleSource.asObservable();

  constructor() { }

  changeNavToggle(navToggle: boolean): void {
    this.navToggleSource.next(navToggle);
  }

}
