import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/shared/services/auth.service";

interface dialogData {
    email: string,
  }
  
  @Component({
    selector: 'password-reset-dialog',
    templateUrl: 'password-reset-dialog.html',
    styleUrls: ['./password-reset-dialog.scss']
  })
  
  export class PasswordResetDialog implements OnInit {
    myControl = new FormControl('');
  
    constructor(
      private snackBar: MatSnackBar,
      public dialog: MatDialog,
      private authService: AuthService,
      public dialogRef: MatDialogRef<PasswordResetDialog>,
      @Inject(MAT_DIALOG_DATA) public data: dialogData,
      private translate: TranslateService
    ) { }
  
    @HostListener('window:keyup.Enter', ['$event'])
    onDialogClick(event: KeyboardEvent): void {
      this.submit();
    }
    
    ngOnInit() {
      this.myControl.addValidators(Validators.email);
      this.myControl.addValidators(Validators.required);
    }
  
  
    resetInput() {
      this.myControl.reset();
    }
  
    submit() {
      if (this.myControl.valid) {
        this.authService.requestPasswordReset(this.myControl.value).subscribe(
          res => { 
            this.dialogRef.close() 
            this.snackBar.open("A password recovery link has been sent to your email address.", 'CANCEL', {
              duration: 3000,
            })
          },
          error => {
            this.snackBar.open("Email doesn't match any account.", 'CANCEL', {
              duration: 3000,
            })
          }
        )
      }
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }
  