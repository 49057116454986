import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StepperFormComponent } from '../stepper-form.component';

@Component({
  selector: 'talentway-fe-file-handler',
  templateUrl: './file-handler.component.html',
  styleUrls: ['./file-handler.component.scss'],
})
export class FileHandlerComponent implements OnInit {
  @Input() form: FormGroup;
  @Output() newFileAdded: EventEmitter<any> = new EventEmitter();
  @Output() download: EventEmitter<FormGroup> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  addedNewFileToUpload($event){
    this.newFileAdded.emit($event)
  }

  downloadAttachment(){
    this.download.emit(this.form)
  }
}
