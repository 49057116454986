import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationClass } from '@talentway/shared'
import { BodyNotificationResponse } from '../models/responses/body-notifications-response.model';
import { BodyNullResponse } from '../models/responses/body-null-response.model';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  getNotificationsURL = 'get-notifications';
  getNotificationsBadgeURL = 'get-notifications-badge';
  notificationSeenURL = 'notification-seen';

  notificationList: NotificationClass[] = [];
  private notificationSource = new BehaviorSubject(this.notificationList);
  currentNotification = this.notificationSource.asObservable();


  notificationListPaginated: NotificationClass[] = [];
  private notificationSourcePaginated = new BehaviorSubject(this.notificationListPaginated);
  currentNotificationPaginated = this.notificationSourcePaginated.asObservable();



  matBadgeValueData = 0;
  private matBadgeValue = new BehaviorSubject(this.matBadgeValueData);
  currentmatBadgeValue = this.matBadgeValue.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  private getHTTPHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json');
  }

  pushNotifications(bodyNotifications: BodyNotificationResponse): void {
    this.clearNotificationList(this.notificationListPaginated);
    this.notificationSourcePaginated.next(bodyNotifications.body);
    const matBadgeVal = this.matBadgeValue.value;
    this.matBadgeValue.next(matBadgeVal + bodyNotifications.total);
  }

  pushNotificationsForBadge(bodyNotifications: BodyNotificationResponse): void {
    this.clearNotificationList(this.notificationList);
    this.notificationSource.next(bodyNotifications.body);
  }

  closeNotification(id: number): void {
    this.notificationList[id].closed = true;
    const matBadgeVal = this.matBadgeValue.value;
    this.matBadgeValue.next(matBadgeVal - 1);
  }

  removeNotification(id: number): void {
    this.notificationList[id].closed = true;
    this.notificationList.splice(id, 1);
    const matBadgeVal = this.matBadgeValue.value;
    this.matBadgeValue.next(matBadgeVal - 1);
  }

  clearNotificationList(list: NotificationClass[]): void {
    list.splice(0);
    const matBadgeVal = this.matBadgeValue.value;
    this.matBadgeValue.next(0);
  }

  getNotifications(tenantID: number, limit?: string, offset?: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tenantID', tenantID ? tenantID : '');
    queryParams = queryParams.append('limit', limit ? limit : '10');
    queryParams = queryParams.append('offset', offset ? offset : '0');

    return this.http.get<any>(environment.apiUrl + this.getNotificationsURL, { params: queryParams });
  }

  getNotificationsForBadge(tenantID: number, limit?: string, offset?: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tenantID', tenantID ? tenantID : '');

    return this.http.get<any>(environment.apiUrl + this.getNotificationsBadgeURL, { params: queryParams });
  }


  notificationSeen(notificationID: number): Observable<BodyNullResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('notificationID', notificationID ? notificationID : '');

    return this.http.put<BodyNullResponse>(environment.apiUrl + this.notificationSeenURL, JSON.stringify(notificationID), {
      headers: httpHeaders,
      params: queryParams
    });
  }

}
