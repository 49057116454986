<app-stepper #cdkStepper *ngIf="allCVOptionsFromDB && formLoaded" [linear]="false">
    <cdk-step label="{{'stepperForm.personalInfo.label' | translate}}" [stepControl]="newRegistrationFormPersonalInfo" [optional]="false">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || selectedI != value && !newRegistrationFormPersonalInfo.valid">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && newRegistrationFormPersonalInfo.valid">edit</mat-icon>
            </span>
        <span class="lable-text">{{'stepperForm.personalInfo.label' | translate}}</span>
        </ng-template>
        <form [formGroup]="newRegistrationFormPersonalInfo">
        <div class="outer-card-section w-100 inner-form-section container">
            <div class="field is-horizontal inner-card-section">
                <div class="field-body w-100">
                    <h2 class="cursor-pointer">{{'stepperForm.personalInfo.label' | translate}}</h2>
                    <h4>{{'stepperForm.personalInfo.title' | translate}}</h4>
                    <mat-form-field appearance="outline" class=" mt-4" *ngIf="allCVOptionsFromDB">
                        <mat-label>{{'stepperForm.personalInfo.gender' | translate}}</mat-label>
                        <input type="text"
                                placeholder="{{'stepperForm.examplePlaceHolders.pickOne' | translate}}"
                                aria-label="type"
                                matInput
                                formControlName="gender"
                                [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let item of filteredGender | async" [value]="item">
                                {{item | translate}}
                            </mat-option>
                            </mat-autocomplete>
                    </mat-form-field>
                    <div class="d-flex justify-content-between two-field-row">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'stepperForm.personalInfo.firstName' | translate}}</mat-label>
                            <input formControlName="name" [readonly]="user !== null" matInput matNativeControl placeholder="Ex. Peter" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'stepperForm.personalInfo.lastName' | translate}}</mat-label>
                            <input formControlName="surname" [readonly]="user !== null" matInput matNativeControl placeholder="Ex. Paser" required>
                        </mat-form-field>
                    </div>
                    <mat-slide-toggle formControlName="showEducationTitle" class="cursor-pointer">{{'stepperForm.personalInfo.addTitle' | translate}}</mat-slide-toggle>

                    <div class="d-flex justify-content-between two-field-row" *ngIf="newRegistrationFormPersonalInfo.controls.showEducationTitle.value">
                        <mat-form-field appearance="outline">
                            <mat-label>{{'stepperForm.personalInfo.addTitleP' | translate}}</mat-label>
                            <input formControlName="title" matInput matNativeControl placeholder="{{'stepperForm.personalInfo.addTitlePlaceholder' | translate}}">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'stepperForm.personalInfo.addTitlePAfterName' | translate}}</mat-label>
                            <input formControlName="titleAfterName" matInput matNativeControl placeholder="{{'stepperForm.personalInfo.addTitlePAfterNamePlaceholder' | translate}}">
                        </mat-form-field>
                    </div>
                    <div class="form-field d-flex flex-column">
                        <mat-form-field appearance="outline">
                               <mat-label>{{'stepperForm.personalInfo.birthDate' | translate}}</mat-label>
                            <input (dateChange)="setDatePersonalInfo($event)" [matDatepicker]="dp" formControlName="birthDate" placeholder="Ex. 4/12/1995"
                                    matInput required>
                            <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #dp panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-field d-flex flex-column">
                        <h4>Contact Information</h4>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'stepperForm.personalInfo.email' | translate}}</mat-label>
                            <input formControlName="email" [readonly]="user !== null" matInput matNativeControl placeholder="Ex. me@gmail.com" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'stepperForm.personalInfo.telephoneNumber' | translate}}</mat-label>
                            <input formControlName="telephone" matInput matNativeControl placeholder="Ex. +421 123 456 789" required>
                        </mat-form-field>
                    </div>
                    <div class="form-field">
                        <h4>Address</h4>
                        <div class="d-flex justify-content-between flex-column">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.personalInfo.streetName' | translate}}</mat-label>
                                <input formControlName="streetName" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.street' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.personalInfo.streetNumber' | translate}}</mat-label>
                                <input formControlName="streetNumber" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.streetNumber' | translate}}">
                            </mat-form-field>
                            <div class="d-flex justify-content-between two-field-row">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'stepperForm.personalInfo.zip' | translate}}</mat-label>
                                    <input formControlName="zip" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.zip' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'stepperForm.personalInfo.city' | translate}}</mat-label>
                                    <input formControlName="city" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.city' | translate}}">
                                </mat-form-field>
                            </div>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <footer data-first class="new-registration-navigation">
                    <button data-first-next mat-button cdkStepperNext type="submit" (click)="saveNewRegistrationPersonalInfo(newRegistrationFormPersonalInfo)" [disabled]="!newRegistrationFormPersonalInfo.valid" [ngClass]="newRegistrationFormPersonalInfo.valid ? 'valid-submit-button' : ''">{{'shared.next' | translate}}</button>
            </footer>
        </div>
        </form>
    </cdk-step>

    <cdk-step label="{{'stepperForm.highSchool.label' | translate}}"  [stepControl]="newRegistrationFormHighSchool" [optional]="false">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || selectedI != value && !newRegistrationFormHighSchool.valid">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && newRegistrationFormHighSchool.valid">edit</mat-icon>
            </span>
            <span class="lable-text">{{'stepperForm.highSchool.label' | translate}}</span>
            </ng-template>
            <form #formDirective="ngForm" [formGroup]="newRegistrationFormHighSchool">
            <div class="outer-card-section w-100 inner-form-section container">
                <div class="field is-horizontal inner-card-section">
                    <div class="outer-card-section w-100 inner-form-section" *ngIf="newRegistrationHighSchool.length">
                        <div class="inner-card-section mb-4" >
                            <div class="added-items w-100 text-center d-flex flex-column justify-content-around align-items-center" *ngFor="let school of newRegistrationHighSchool; let i = index">
                                <mat-divider *ngIf="i >= 1" class="mb-4 mt-4 w-100"></mat-divider>
                                <div *ngIf="!school.edit" class=" w-100 content d-flex justify-content-between align-items-center">
                                    <div class="newItemContent">
                                        <h3>{{school.highSchoolName}}</h3>
                                        <p class="mb-0">{{school.endDate | date:'d.M.Y'}}</p>
                                    </div>
                                    <aside>
                                        <mat-icon *ngIf="!tempEditnewRegistrationHighSchool" (click)="editItemOfNewRegistrationHighSchool(newRegistrationFormHighSchool, newRegistrationHighSchool, i)" class="ml-2 mr-2 mt-1">edit</mat-icon>
                                        <mat-icon  (click)="removeItem( newRegistrationHighSchool, i)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                    </aside>
                                </div>
                                <div *ngIf="school.edit" class="editItem w-100">
                                    <div class="field-body text-center">
                                        <mat-form-field appearance="outline" required>
                                            <mat-label>{{'stepperForm.highSchool.hsNAme' | translate}}</mat-label>
                                            <input formControlName="highSchoolName" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.highSchoolName' | translate}}">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" required>
                                            <mat-label>{{'stepperForm.highSchool.hsField' | translate}}</mat-label>
                                            <input formControlName="fieldOfStudy" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.highSchoolField' | translate}}">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" required>
                                            <mat-label>{{'stepperForm.personalInfo.city' | translate}}</mat-label>
                                            <input formControlName="city" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.city' | translate}}">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" required>
                                            <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                            <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{'stepperForm.highSchool.startDate' | translate}}</mat-label>
                                            <input (dateChange)="setStartDateHighSchool($event)" [matDatepicker]="dp1" formControlName="startDate" placeholder="Ex. 4/12/1995"
                                                    matInput required>
                                            <mat-datepicker-toggle [for]="dp1" matSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #dp1 panelClass="example-month-picker">
                                            </mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{'stepperForm.highSchool.endDate' | translate}}</mat-label>
                                            <input (dateChange)="setEndDateHighSchool($event)" [matDatepicker]="dp" formControlName="endDate" placeholder="Ex. 4/12/1995" matInput required>
                                            <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #dp panelClass="example-month-picker">
                                            </mat-datepicker>
                                        </mat-form-field>
                                        <talentway-fe-file-handler
                                            [form]="newRegistrationFormHighSchool"
                                            (newFileAdded)="addedNewFileToUpload($event, newRegistrationFormHighSchool)"
                                            (download)="downloadAttachment(newRegistrationFormHighSchool)"
                                            ></talentway-fe-file-handler>
                                    </div>
                                    <div class="edit-nav d-flex justify-content-evenly">
                                        <button data-first-save type="reset" (click)="closeEditItemOfNewRegistrationHighSchool(newRegistrationFormHighSchool, newRegistrationHighSchool, i)" class="close">{{'shared.close' | translate}}</button>
                                        <button data-first-save (click)="saveEditItemOfNewRegistrationHighSchool(newRegistrationFormHighSchool, newRegistrationHighSchool, i)" [disabled]="!newRegistrationFormHighSchool.valid"  [ngClass]="newRegistrationFormHighSchool.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                                        <mat-icon  (click)="removeItem( newRegistrationHighSchool, i, true)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="outer-card-section outer-card inner-form-section">
                        <h2 class="cursor-pointer">{{'stepperForm.highSchool.title' | translate}}</h2>
                        <h4>{{'stepperForm.highSchool.header' | translate}}</h4>
                        <p (click)="createNewRegistrationHighSchool(newRegistrationFormHighSchool)" class="cursor-pointer p-4">+ {{'stepperForm.highSchool.addHS' | translate}}</p>
                        <div *ngIf="this.newRegistrationFormHighSchool.controls.addNewHighSchool.value" class="inner-card-section new-item">
                            <div class="field-body text-center w-100">
                                <h4>{{'stepperForm.highSchool.hs' | translate}}</h4>
                                <mat-form-field appearance="outline" required>
                                    <mat-label>{{'stepperForm.highSchool.hsNAme' | translate}}</mat-label>
                                    <input formControlName="highSchoolName" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.highSchoolName' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="outline" required>
                                    <mat-label>{{'stepperForm.highSchool.hsField' | translate}}</mat-label>
                                    <input formControlName="fieldOfStudy" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.highSchoolField' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="outline" required>
                                    <mat-label>{{'stepperForm.personalInfo.city' | translate}}</mat-label>
                                    <input formControlName="city" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.city' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="outline" required>
                                    <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                    <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'stepperForm.highSchool.startDate' | translate}}</mat-label>
                                    <input (dateChange)="setStartDateHighSchool($event)" [matDatepicker]="dp1" formControlName="startDate" placeholder="Ex. 4/12/1995"
                                            matInput required>
                                    <mat-datepicker-toggle [for]="dp1" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #dp1 panelClass="example-month-picker">
                                    </mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'stepperForm.highSchool.endDate' | translate}}</mat-label>
                                    <input (dateChange)="setEndDateHighSchool($event)" [matDatepicker]="dp2" formControlName="endDate" placeholder="Ex. 4/12/1995" matInput required>
                                    <mat-datepicker-toggle [for]="dp2" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #dp2 panelClass="example-month-picker">
                                    </mat-datepicker>
                                </mat-form-field>
                                <talentway-fe-file-handler
                                [form]="newRegistrationFormHighSchool"
                                (newFileAdded)="addedNewFileToUpload($event, newRegistrationFormHighSchool)"
                                (download)="downloadAttachment(newRegistrationFormHighSchool)"
                                ></talentway-fe-file-handler>
                            </div>
                            <div class="new-item-nav">
                                <button data-first-save type="reset" (click)="closeCreateNewRegistrationHighSchool(newRegistrationFormHighSchool)" class="close">{{'shared.close' | translate}}</button>
                                <button data-first-save (click)="saveNewHighSchool(newRegistrationFormHighSchool)"  [ngClass]="newRegistrationFormHighSchool.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="new-registration-navigation" [ngClass]="!this.togleNav ? 'activeNav' : ''">
                    <button mat-button cdkStepperPrevious>{{'shared.back' | translate}}</button>
                    <button data-second-next mat-button
                    (click)="goToNextStepFromHighSchool()"
                    [ngClass]="newRegistrationFormHighSchool.disabled && !tempnewRegistrationHighSchool && !tempEditnewRegistrationHighSchool ? 'valid-submit-button' : ''">{{'shared.next' | translate}}</button>
                </footer>
            </div>
            </form>
    </cdk-step>

    <cdk-step label="{{'stepperForm.university.label' | translate}}" [stepControl]="newRegistrationFormUniversity" [optional]="true">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || selectedI != value && !newRegistrationFormUniversity.valid">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && newRegistrationFormUniversity.valid">edit</mat-icon>
            </span>
        <span class="lable-text">{{'stepperForm.university.label' | translate}}</span>
        </ng-template>
        <form [formGroup]="newRegistrationFormUniversity">
        <div class="outer-card-section w-100 inner-form-section container">
            <div class="field is-horizontal inner-card-section">
                <div class="outer-card-section w-100 inner-form-section" *ngIf="newRegistrationUniversity.length">
                    <div class="inner-card-section mb-4" >
                        <div class="added-items w-100 text-center d-flex flex-column justify-content-around align-items-center" *ngFor="let school of newRegistrationUniversity; let i = index">
                            <mat-divider *ngIf="i >= 1" class="mb-4 mt-4 w-100"></mat-divider>
                            <div *ngIf="!school.edit" class=" w-100 content d-flex justify-content-between align-items-center">
                                <div class="newItemContent">
                                    <h3>{{school.universityName}}</h3>
                                    <p class="mb-0">{{!school.current ? (school.endDate | date:'d.M.Y') : 'Currently studying'}}</p>
                                </div>
                                <aside>
                                    <mat-icon *ngIf="!tempEditnewRegistrationUniversity" (click)="editItemOfNewRegistrationUniversity(newRegistrationFormUniversity, newRegistrationUniversity, i)" class="ml-2 mr-2 mt-1">edit</mat-icon>
                                    <mat-icon  (click)="removeItem( newRegistrationUniversity, i)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </aside>
                            </div>
                            <div *ngIf="school.edit" class="editItem w-100">
                                <div class="field-body text-center">
                                    <mat-form-field appearance="outline" required>
                                    <mat-label>{{'stepperForm.university.uName' | translate}}</mat-label>
                                    <input formControlName="universityName" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.university' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" required>
                                        <mat-label>{{'stepperForm.university.uField' | translate}}</mat-label>
                                        <input formControlName="fieldOfStudy" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.degree' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" required>
                                        <mat-label>{{'stepperForm.personalInfo.city' | translate}}</mat-label>
                                        <input formControlName="city" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.city' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" required>
                                        <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                        <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.highSchool.startDate' | translate}}</mat-label>
                                        <input (dateChange)="setStartDateUniversity($event)" [matDatepicker]="dp" formControlName="startDate" placeholder="Ex. 4/12/1995"
                                                matInput required>
                                        <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker #dp panelClass="example-month-picker">
                                        </mat-datepicker>
                                    </mat-form-field>
                                    <mat-slide-toggle formControlName="current" class="cursor-pointer">{{'stepperForm.university.currentlyStudying' | translate}}</mat-slide-toggle>
                                    <div class="form-field" *ngIf="!newRegistrationFormUniversity.controls.current.value">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{'stepperForm.highSchool.endDate' | translate}}</mat-label>
                                            <input (dateChange)="setEndDateUniversity($event)" [matDatepicker]="dp" formControlName="endDate" placeholder="Ex. 4/12/1995" matInput>
                                            <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #dp panelClass="example-month-picker">
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <talentway-fe-file-handler
                                    [form]="newRegistrationFormUniversity"
                                    (newFileAdded)="addedNewFileToUpload($event, newRegistrationFormUniversity)"
                                    (download)="downloadAttachment(newRegistrationFormUniversity)"
                                    ></talentway-fe-file-handler>
                                </div>
                                <div class="edit-nav d-flex justify-content-evenly">
                                    <button data-first-save type="reset" (click)="closeEditItemOfNewRegistrationUniversity(newRegistrationFormUniversity, newRegistrationUniversity, i)" class="close">{{'shared.close' | translate}}</button>
                                    <button data-first-save (click)="saveEditItemOfNewRegistrationUniversity(newRegistrationFormUniversity, newRegistrationUniversity, i)" [disabled]="!newRegistrationFormUniversity.valid"  [ngClass]="newRegistrationFormUniversity.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                                    <mat-icon  (click)="removeItem( newRegistrationUniversity, i, true)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="outer-card-section outer-card inner-form-section">
                    <h2 class="cursor-pointer">{{'stepperForm.university.title' | translate}}</h2>
                    <h4>{{'stepperForm.university.header' | translate}}</h4>
                    <p (click)="createNewRegistrationUniversity(newRegistrationFormUniversity)" class="cursor-pointer p-4">+ {{'stepperForm.university.addUniversity' | translate}}</p>
                    <div *ngIf="this.newRegistrationFormUniversity.controls.addNewUniversity.value" class="inner-card-section new-item">
                        <div class="field-body text-center w-100">
                            <h4>{{'stepperForm.university.title' | translate}}</h4>
                            <mat-form-field appearance="outline" required>
                                <mat-label>{{'stepperForm.university.uName' | translate}}</mat-label>
                                <input formControlName="universityName" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.university' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline" required>
                                <mat-label>{{'stepperForm.university.uField' | translate}}</mat-label>
                                <input formControlName="fieldOfStudy" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.degree' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline" required>
                                <mat-label>{{'stepperForm.personalInfo.city' | translate}}</mat-label>
                                <input formControlName="city" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.city' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline" required>
                                <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.highSchool.startDate' | translate}}</mat-label>
                                <input (dateChange)="setStartDateUniversity($event)" [matDatepicker]="dp" formControlName="startDate" placeholder="Ex. 4/12/1995" matInput required>
                                <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #dp panelClass="example-month-picker">
                                </mat-datepicker>
                            </mat-form-field>
                            <mat-slide-toggle formControlName="current" class="cursor-pointer">{{'stepperForm.university.currentlyStudying' | translate}}</mat-slide-toggle>
                            <div class="form-field" *ngIf="!newRegistrationFormUniversity.controls.current.value">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'stepperForm.highSchool.endDate' | translate}}</mat-label>
                                    <input (dateChange)="setEndDateUniversity($event)" [matDatepicker]="dp" formControlName="endDate" placeholder="Ex. 4/12/1995"
                                            matInput>
                                    <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #dp panelClass="example-month-picker">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>
                            <talentway-fe-file-handler
                            [form]="newRegistrationFormUniversity"
                            (newFileAdded)="addedNewFileToUpload($event, newRegistrationFormUniversity)"
                            (download)="downloadAttachment(newRegistrationFormUniversity)"
                            ></talentway-fe-file-handler>
                        </div>
                        <div class="new-item-nav">
                            <button data-first-save type="reset" (click)="closeCreateNewRegistrationUniversity(newRegistrationFormUniversity)" class="close">{{'shared.close' | translate}}</button>
                            <button data-first-save (click)="saveNewUniversity(newRegistrationFormUniversity)" [ngClass]="newRegistrationFormUniversity.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="new-registration-navigation" [ngClass]="!this.togleNav ? 'activeNav' : ''">
                <button mat-button cdkStepperPrevious>{{'shared.back' | translate}}</button>
                <button data-second-next mat-button
                    (click)="goToNextStepFromUniversity()"
                    [ngClass]="newRegistrationFormUniversity.disabled? 'valid-submit-button' : ''">{{'shared.next' | translate}}</button>
            </footer>
        </div>
        </form>
    </cdk-step>

    <!-- <cdk-step label="Courses" [stepControl]="newRegistrationFormCourse" [optional]="true">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || selectedI != value && !newRegistrationFormCourse.valid">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && newRegistrationFormCourse.valid">edit</mat-icon>
            </span>
        <span class="lable-text">Courses</span>
        </ng-template>
        <form [formGroup]="newRegistrationFormCourse">
        <div class="outer-card-section w-100 inner-form-section container">
            <div class="field is-horizontal inner-card-section">
                <div class="outer-card-section w-100 inner-form-section" *ngIf="newRegistrationCourse.length">
                    <div class="inner-card-section mb-4" >
                        <div class="added-items w-100 text-center d-flex flex-column justify-content-around align-items-center" *ngFor="let course of newRegistrationCourse; let i = index">
                            <mat-divider *ngIf="i >= 1" class="mb-4 mt-4 w-100"></mat-divider>
                            <div *ngIf="!course.edit" class=" w-100 content d-flex justify-content-between align-items-center">
                                <div class="newItemContent">
                                    <h3>{{course.courseName}}</h3>
                                        <p class="mb-0">{{course.endDate | date:'d.M.Y'}}</p>
                                </div>
                                <aside>
                                    <mat-icon *ngIf="!tempEditnewRegistrationCourse" (click)="editItemOfNewRegistrationCourse(newRegistrationFormCourse, newRegistrationCourse, i)" class="ml-2 mr-2 mt-1">edit</mat-icon>
                                    <mat-icon  (click)="removeItem( newRegistrationCourse, i)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </aside>
                            </div>
                            <div *ngIf="course.edit" class="editItem w-100">
                                <div class="field-body text-center">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Course name</mat-label>
                                        <input formControlName="courseName" matInput matNativeControl placeholder="Ex. 5769">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Certificate</mat-label>
                                        <input formControlName="certificate" matInput matNativeControl placeholder="Ex. 5769">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>End date</mat-label>
                                        <input (dateChange)="setEndDateCourse($event)" [matDatepicker]="dp" formControlName="endDate" placeholder="Ex. 4/12/1995" matInput required>
                                        <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker #dp panelClass="example-month-picker">
                                        </mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Specification</mat-label>
                                        <input formControlName="specification" matInput matNativeControl placeholder="Ex. 5769">
                                    </mat-form-field>
                                </div>
                                <div class="edit-nav d-flex justify-content-evenly">
                                    <button data-first-save type="reset" (click)="closeEditItemOfNewRegistrationCourse(newRegistrationFormCourse, newRegistrationCourse, i)" class="close">Close</button>
                                    <button data-first-save (click)="saveEditItemOfNewRegistrationCourse(newRegistrationFormCourse, newRegistrationCourse, i)" [disabled]="!newRegistrationFormCourse.valid"  [ngClass]="newRegistrationFormCourse.valid ? 'valid-submit-button' : ''" class="save">Uložiť</button>
                                    <mat-icon  (click)="removeItem( newRegistrationCourse, i, true)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="outer-card-section outer-card inner-form-section">
                    <h2 class="cursor-pointer">Máte zasebou sebou nejaké kurzy?</h2>
                    <h4>Dajte zamestnávateľom vedieť, aké kurzy ste si spravili.</h4>
                    <p (click)="createNewRegistrationCourse(newRegistrationFormCourse)" class="cursor-pointer p-4">+ Add Courses</p>
                    <div *ngIf="this.newRegistrationFormCourse.controls.addNewCourse.value" class="inner-card-section new-item">
                        <div class="field-body text-center w-100">
                            <h4>Course</h4>
                            <mat-form-field appearance="outline">
                                <mat-label>Course name</mat-label>
                                <input formControlName="courseName" matInput matNativeControl placeholder="Ex. 5769">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Certificate</mat-label>
                                <input formControlName="certificate" matInput matNativeControl placeholder="Ex. 5769">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>End date</mat-label>
                                <input (dateChange)="setEndDateCourse($event)" [matDatepicker]="dp" formControlName="endDate" placeholder="Ex. 4/12/1995"
                                        matInput required>
                                <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #dp panelClass="example-month-picker">
                                </mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Specification</mat-label>
                                <input formControlName="specification" matInput matNativeControl placeholder="Ex. 5769">
                            </mat-form-field>
                        </div>
                        <div class="new-item-nav">
                            <button data-first-save type="reset" (click)="closeCreateNewRegistrationCourse(newRegistrationFormCourse)" class="close">Close</button>
                            <button data-first-save (click)="saveNewCourse(newRegistrationFormCourse)" [disabled]="!newRegistrationFormCourse.valid"  [ngClass]="newRegistrationFormCourse.valid ? 'valid-submit-button' : ''" class="save">Uložiť</button>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="new-registration-navigation" [ngClass]="!this.togleNav ? 'activeNav' : ''">
                <button mat-button cdkStepperPrevious>Back</button>
                <button data-second-next mat-button
                (click)="goToNextStepFromCourse()"
                [ngClass]="!this.tempnewRegistrationCourse && !this.tempEditnewRegistrationCourse ? 'valid-submit-button' : ''">{{'shared.next' | translate}}</button>
            </footer>
        </div>
        </form>
    </cdk-step> -->

    <cdk-step label="{{'stepperForm.projects.label' | translate}}" [stepControl]="newRegistrationFormProject" [optional]="true">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || selectedI != value && !newRegistrationFormProject.valid">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && newRegistrationFormProject.valid">edit</mat-icon>
            </span>
        <span class="lable-text">{{'stepperForm.projects.label' | translate}}</span>
        </ng-template>
        <form [formGroup]="newRegistrationFormProject">
        <div class="outer-card-section w-100 inner-form-section container">
            <div class="field is-horizontal inner-card-section">
                <div class="outer-card-section w-100 inner-form-section" *ngIf="newRegistrationProject.length">
                    <div class="inner-card-section mb-4" >
                        <div class="added-items w-100 text-center d-flex flex-column justify-content-around align-items-center" *ngFor="let project of newRegistrationProject; let i = index">
                            <mat-divider *ngIf="i >= 1" class="mb-4 mt-4 w-100"></mat-divider>
                            <div *ngIf="!project.edit" class=" w-100 content d-flex justify-content-between align-items-center">
                                <div class="newItemContent">
                                    <h3>{{project.title}}</h3>
                                    <p class="mb-0">{{project.duration}}</p>
                                </div>
                                <aside>
                                    <mat-icon *ngIf="!tempEditnewRegistrationProject" (click)="editItemOfNewRegistrationProject(newRegistrationFormProject, newRegistrationProject, i)" class="ml-2 mr-2 mt-1">edit</mat-icon>
                                    <mat-icon  (click)="removeItem( newRegistrationProject, i)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </aside>
                            </div>
                            <div *ngIf="project.edit" class="editItem w-100">
                                <div class="field-body text-center">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.projects.title' | translate}}</mat-label>
                                        <input formControlName="title" matInput matNativeControl placeholder="Ex. HelpYou">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.projects.position' | translate}}</mat-label>
                                        <input formControlName="position" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.position' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.projects.duration' | translate}}</mat-label>
                                        <input formControlName="duration" matInput matNativeControl placeholder="Ex. 4">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                        <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                                    </mat-form-field>
                                </div>
                                <div class="edit-nav d-flex justify-content-evenly">
                                    <button data-first-save type="reset" (click)="closeEditItemOfNewRegistrationProject(newRegistrationFormProject, newRegistrationProject, i)" class="close">{{'shared.close' | translate}}</button>
                                    <button data-first-save (click)="saveEditItemOfNewRegistrationProject(newRegistrationFormProject, newRegistrationProject, i)" [disabled]="!newRegistrationFormProject.valid"  [ngClass]="newRegistrationFormProject.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                                    <mat-icon  (click)="removeItem( newRegistrationProject, i, true)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="outer-card-section outer-card inner-form-section">
                        <h2 class="cursor-pointer">{{'stepperForm.projects.header' | translate}}</h2>
                        <h4>{{'stepperForm.projects.subHeader' | translate}}</h4>
                    <p (click)="createNewRegistrationProject(newRegistrationFormProject)" class="cursor-pointer p-4">+ {{'stepperForm.projects.addProject' | translate}}</p>
                    <div *ngIf="this.newRegistrationFormProject.controls.addNewProject.value" class="inner-card-section new-item">
                        <div class="field-body text-center w-100">
                            <h4>Project</h4>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.projects.title' | translate}}</mat-label>
                                <input formControlName="title" matInput matNativeControl placeholder="Ex. HelpYou">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.projects.position' | translate}}</mat-label>
                                <input formControlName="position" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.position' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.projects.duration' | translate}}</mat-label>
                                <input formControlName="duration" matInput matNativeControl placeholder="Ex. 4">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                            </mat-form-field>
                        </div>
                        <div class="new-item-nav">
                            <button data-first-save type="reset" (click)="closeCreateNewRegistrationProject(newRegistrationFormProject)" class="close">Close</button>
                            <button data-first-save (click)="saveNewProject(newRegistrationFormProject)" [ngClass]="newRegistrationFormProject.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="new-registration-navigation" [ngClass]="!this.togleNav ? 'activeNav' : ''">
                    <button mat-button cdkStepperPrevious>Back</button>
                    <button data-second-next mat-button
                    (click)="goToNextStepFromProject()"
                    [ngClass]="!this.tempnewRegistrationProject && !this.tempEditnewRegistrationProject ? 'valid-submit-button' : ''">{{'shared.next' | translate}}</button>
            </footer>
        </div>
        </form>
    </cdk-step>

    <cdk-step label="{{'stepperForm.language.label' | translate}}" [stepControl]="newRegistrationFormLanguages" [optional]="false">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || selectedI != value && !newRegistrationFormLanguages.valid">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && newRegistrationFormLanguages.valid">edit</mat-icon>
            </span>
        <span class="lable-text">{{'stepperForm.language.label' | translate}}</span>
        </ng-template>
        <form #formDirective="ngForm" [formGroup]="newRegistrationFormLanguages">
        <div class="outer-card-section w-100 inner-form-section container">
            <div class="field is-horizontal inner-card-section">
                <div class="outer-card-section w-100 inner-form-section" *ngIf="newRegistrationLanguages.length">
                    <div class="inner-card-section mb-4" >
                        <div class="added-items w-100 text-center d-flex flex-column justify-content-around align-items-center" *ngFor="let language of newRegistrationLanguages; let i = index">
                            <mat-divider *ngIf="i >= 1" class="mb-4 mt-4 w-100"></mat-divider>
                            <div *ngIf="!language.edit" class=" w-100 content d-flex justify-content-between align-items-center">
                                <div class="newItemContent">
                                    <h3>{{language.language}}</h3>
                                    <p class="mb-0">{{language.level}}</p>
                                </div>
                                <aside>
                                    <mat-icon *ngIf="!tempEditnewRegistrationLanguages" (click)="editItemOfNewRegistrationLanguages(newRegistrationFormLanguages, newRegistrationLanguages, i)" class="ml-2 mr-2 mt-1">edit</mat-icon>
                                    <mat-icon  (click)="removeItem( newRegistrationLanguages, i)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </aside>
                            </div>
                            <div *ngIf="language.edit" class="editItem w-100">
                                <div class="field-body text-center">
                                    <mat-form-field appearance="outline" class=" mt-4">
                                        <mat-label>{{'stepperForm.language.language' | translate}}</mat-label>
                                        <input type="text"
                                        placeholder="Pick one"
                                        aria-label="type"
                                        matInput
                                        formControlName="language"
                                        [matAutocomplete]="auto">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let item of filteredLang | async" [value]="item">
                                                {{item}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class=" mt-4">
                                        <mat-label>{{'stepperForm.language.level' | translate}}</mat-label>
                                        <mat-select formControlName="level" matNativeControl required>
                                            <mat-option *ngFor="let item of this.allCVOptionsFromDB.languageLevels" [value]="item">
                                                {{item}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="edit-nav d-flex justify-content-evenly">
                                    <button data-first-save type="reset" (click)="closeEditItemOfNewRegistrationLanguages(newRegistrationFormLanguages, newRegistrationLanguages, i)" class="close">{{'shared.close' | translate}}</button>
                                    <button data-first-save (click)="saveEditItemOfNewRegistrationLanguages(newRegistrationFormLanguages, newRegistrationLanguages, i)" [disabled]="!newRegistrationFormLanguages.valid"  [ngClass]="newRegistrationFormLanguages.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                                    <mat-icon  (click)="removeItem( newRegistrationLanguages, i, true)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="outer-card-section outer-card inner-form-section">
                    <h2 class="cursor-pointer">{{'stepperForm.language.title' | translate}}</h2>
                    <h4>{{'stepperForm.language.header' | translate}}</h4>
                    <p (click)="createNewRegistrationLanguage(newRegistrationFormLanguages)" class="cursor-pointer  p-4">+ {{'stepperForm.language.addLanguage' | translate}}</p>
                    <div *ngIf="this.newRegistrationFormLanguages.controls.addNewLanguage.value" class="inner-card-section new-item">
                        <div class="field-body text-center w-100">
                            <h4>{{'stepperForm.language.language' | translate}}</h4>
                            <mat-form-field appearance="outline" class=" mt-4">
                                <mat-label>{{'stepperForm.language.language' | translate}}</mat-label>
                                <input type="text"
                                placeholder="Pick one"
                                aria-label="type"
                                matInput
                                formControlName="language"
                                [matAutocomplete]="auto">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let item of filteredLang | async" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class=" mt-4">
                                <mat-label>{{'stepperForm.language.level' | translate}}</mat-label>
                                <mat-select formControlName="level" matNativeControl required>
                                    <mat-option *ngFor="let item of this.allCVOptionsFromDB.languageLevels" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="new-item-nav">
                            <button data-first-save type="reset" (click)="closeCreatenewRegistrationLanguage(newRegistrationFormLanguages)" class="close">{{'shared.close' | translate}}</button>
                            <button (click)="saveNewLanguage(newRegistrationFormLanguages)" [ngClass]="newRegistrationFormLanguages.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="new-registration-navigation" [ngClass]="!this.togleNav ? 'activeNav' : ''">
                <button mat-button cdkStepperPrevious>{{'shared.back' | translate}}</button>
                <button data-second-next mat-button
                (click)="goToNextStepFromLanguages()"
                [ngClass]="this.newRegistrationLanguages.length >0 && !tempnewRegistrationLanguages && !this.tempEditnewRegistrationLanguages ? 'valid-submit-button' : ''">{{'shared.next' | translate}}</button>
            </footer>
        </div>
        </form>
    </cdk-step>

    <cdk-step label="{{'stepperForm.workExperiences.label' | translate}}" [stepControl]="newRegistrationFormWorkExperience" [optional]="false">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || selectedI != value && !newRegistrationFormWorkExperience.valid">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && newRegistrationFormWorkExperience.valid">edit</mat-icon>
            </span>
        <span class="lable-text">{{'stepperForm.workExperiences.label' | translate}}</span>
        </ng-template>
        <form [formGroup]="newRegistrationFormWorkExperience">
        <div class="outer-card-section w-100 inner-form-section container">
            <div class="field is-horizontal inner-card-section">
                <div class="outer-card-section w-100 inner-form-section" *ngIf="newRegistrationWorkExperience.length">
                    <div class="inner-card-section mb-4" >
                        <div class="added-items w-100 text-center d-flex flex-column justify-content-around align-items-center" *ngFor="let work of newRegistrationWorkExperience; let i = index">
                            <mat-divider *ngIf="i >= 1" class="mb-4 mt-4 w-100"></mat-divider>
                            <div *ngIf="!work.edit" class=" w-100 content d-flex justify-content-between align-items-center">
                                <div class="newItemContent">
                                    <h3>{{work.employer}}</h3>
                                    <p class="mb-0">{{!work.current ? (work.endDate | date:'d.M.Y') : 'Currently working'}}</p>
                                </div>
                                <aside>
                                    <mat-icon *ngIf="!tempEditnewRegistrationWorkExperience" (click)="editItemOfNewRegistrationWorkExperience(newRegistrationFormWorkExperience, newRegistrationWorkExperience, i)" class="ml-2 mr-2 mt-1">edit</mat-icon>
                                    <mat-icon  (click)="removeItem( newRegistrationWorkExperience, i)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </aside>
                            </div>
                            <div *ngIf="work.edit" class="editItem w-100">
                                <div class="field-body text-center">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.workExperiences.employer' | translate}}</mat-label>
                                        <input formControlName="employer" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.employer' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.workExperiences.position' | translate}}</mat-label>
                                        <input formControlName="position" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.position' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.personalInfo.city' | translate}}</mat-label>
                                        <input formControlName="city" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.city' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                        <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'stepperForm.highSchool.startDate' | translate}}</mat-label>
                                        <input (dateChange)="setStartDateWorkExperience($event)" [matDatepicker]="dp" formControlName="startDate" placeholder="Ex. 4/12/1995"
                                                matInput required>
                                        <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker #dp panelClass="example-month-picker">
                                        </mat-datepicker>
                                    </mat-form-field>
                                    <mat-slide-toggle formControlName="current" class="cursor-pointer">{{'stepperForm.workExperiences.currentWork' | translate}}</mat-slide-toggle>
                                    <div class="form-field" *ngIf="!newRegistrationFormWorkExperience.controls.current.value">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{'stepperForm.highSchool.endDate' | translate}}</mat-label>
                                            <input (dateChange)="setEndDateWorkExperience($event)" [matDatepicker]="dp" formControlName="endDate" placeholder="Ex. 4/12/1995"
                                                    matInput required>
                                            <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                            <mat-datepicker #dp panelClass="example-month-picker">
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="edit-nav d-flex justify-content-evenly">
                                    <button data-first-save type="reset" (click)="closeEditItemOfNewRegistrationWorkExperience(newRegistrationFormWorkExperience, newRegistrationWorkExperience, i)" class="close">{{'shared.close' | translate}}</button>
                                    <button data-first-save (click)="saveEditItemOfNewRegistrationWorkExperience(newRegistrationFormWorkExperience, newRegistrationWorkExperience, i)" [disabled]="!newRegistrationFormWorkExperience.valid"  [ngClass]="newRegistrationFormWorkExperience.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                                    <mat-icon  (click)="removeItem( newRegistrationWorkExperience, i, true)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="outer-card-section outer-card inner-form-section">
                    <h2 class="cursor-pointer">{{'stepperForm.workExperiences.title' | translate}}</h2>
                    <h4>{{'stepperForm.workExperiences.header' | translate}}</h4>
                    <p (click)="createNewRegistrationWorkExperience(newRegistrationFormWorkExperience)" class="cursor-pointer p-4">+ {{'stepperForm.workExperiences.addWorkExp' | translate}}</p>
                    <div *ngIf="this.newRegistrationFormWorkExperience.controls.addNewWorkExperience.value" class="inner-card-section new-item">
                        <div class="field-body text-center w-100">
                            <h4>{{'stepperForm.workExperiences.label' | translate}}</h4>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.workExperiences.employer' | translate}}</mat-label>
                                <input formControlName="employer" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.employer' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.workExperiences.position' | translate}}</mat-label>
                                <input formControlName="position" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.position' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.personalInfo.city' | translate}}</mat-label>
                                <input formControlName="city" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.city' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.personalInfo.country' | translate}}</mat-label>
                                <input formControlName="country" matInput matNativeControl placeholder="{{'stepperForm.examplePlaceHolders.country' | translate}}">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{'stepperForm.highSchool.startDate' | translate}}</mat-label>
                                <input (dateChange)="setStartDateWorkExperience($event)" [matDatepicker]="dp" formControlName="startDate" placeholder="Ex. 4/12/1995"
                                        matInput required>
                                <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #dp panelClass="example-month-picker">
                                </mat-datepicker>
                            </mat-form-field>
                            <mat-slide-toggle formControlName="current" class="cursor-pointer">{{'stepperForm.workExperiences.currentWork' | translate}}</mat-slide-toggle>
                            <div class="form-field" *ngIf="!newRegistrationFormWorkExperience.controls.current.value">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'stepperForm.highSchool.endDate' | translate}}</mat-label>
                                    <input (dateChange)="setEndDateWorkExperience($event)" [matDatepicker]="dp" formControlName="endDate" placeholder="Ex. 4/12/1995"
                                            matInput>
                                    <mat-datepicker-toggle [for]="dp" matSuffix></mat-datepicker-toggle>
                                    <mat-datepicker #dp panelClass="example-month-picker">
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="new-item-nav">
                            <button data-first-save type="reset" (click)="closeCreateNewRegistrationworkExperience(newRegistrationFormWorkExperience)" class="close">{{'shared.close' | translate}}</button>
                            <button data-first-save (click)="saveNewWorkExperience(newRegistrationFormWorkExperience)" [ngClass]="newRegistrationFormWorkExperience.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="new-registration-navigation" [ngClass]="!this.togleNav ? 'activeNav' : ''">
                <button mat-button cdkStepperPrevious>{{'shared.back' | translate}}</button>
                <button data-second-next mat-button
                (click)="goToNextStepFromWorkExperience()"
                [ngClass]="newRegistrationFormWorkExperience.disabled && !tempnewRegistrationWorkExperience && !tempEditnewRegistrationWorkExperience ? 'valid-submit-button' : ''">{{'shared.next' | translate}}</button>
            </footer>
        </div>
        </form>
    </cdk-step>

    <cdk-step label="{{'stepperForm.skills.label' | translate}}" [stepControl]="newRegistrationFormSkills" [optional]="false">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || selectedI != value && !newRegistrationFormSkills.valid">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && newRegistrationFormSkills.valid">edit</mat-icon>
            </span>
        <span class="lable-text">{{'stepperForm.skills.label' | translate}}</span>
        </ng-template>
        <form [formGroup]="newRegistrationFormSkills">
        <div class="outer-card-section w-100 inner-form-section container">
            <div class="field is-horizontal inner-card-section">
                <div class="outer-card-section w-100 inner-form-section" *ngIf="newRegistrationSkills.length">
                    <div class="inner-card-section mb-4" >
                        <div class="added-items w-100 text-center d-flex flex-column justify-content-around align-items-center" *ngFor="let skill of newRegistrationSkills; let i = index">
                            <mat-divider *ngIf="i >= 1" class="mb-4 mt-4 w-100"></mat-divider>
                            <div *ngIf="!skill.edit" class=" w-100 content d-flex justify-content-between align-items-center">
                                <div class="newItemContent">
                                    <h3>{{skill.skillName}}</h3>
                                    <p class="mb-0">{{skill.level | translate}}</p>
                                </div>
                                <aside>
                                    <mat-icon *ngIf="!tempEditnewRegistrationSkills" (click)="editItemOfNewRegistrationSkill(newRegistrationFormSkills, newRegistrationSkills, i)" class="ml-2 mr-2 mt-1">edit</mat-icon>
                                    <mat-icon  (click)="removeItem( newRegistrationSkills, i)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </aside>
                            </div>
                            <div *ngIf="skill.edit" class="editItem w-100">
                                <div class="field-body text-center">
                                    <mat-form-field appearance="outline" class=" mt-4">
                                        <mat-label>{{'stepperForm.skills.skill' | translate}}</mat-label>
                                        <input type="text"
                                        placeholder="Pick one"
                                        aria-label="type"
                                        matInput
                                        formControlName="skillName"
                                        [matAutocomplete]="auto">
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                            <mat-option *ngFor="let item of filteredSkills | async" [value]="item">
                                                {{item}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class=" mt-4">
                                        <mat-label>{{'stepperForm.language.level' | translate}}</mat-label>
                                        <mat-select formControlName="level" matNativeControl required>
                                            <mat-option *ngFor="let item of this.allCVOptionsFromDB.skillLevels" [value]="item">
                                                {{item | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="edit-nav d-flex justify-content-evenly">
                                    <button data-first-save type="reset" (click)="closeEditItemOfNewRegistrationSkill(newRegistrationFormSkills, newRegistrationSkills, i)" class="close">{{'shared.close' | translate}}</button>
                                    <button data-first-save (click)="saveEditItemOfNewRegistrationSkill(newRegistrationFormSkills, newRegistrationSkills, i)" [disabled]="!newRegistrationFormSkills.valid"  [ngClass]="newRegistrationFormSkills.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                                    <mat-icon  (click)="removeItem( newRegistrationSkills, i, true)" class="ml-2 mr-2 mt-1">delete_forever</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="outer-card-section outer-card inner-form-section">
                    <h2 class="cursor-pointer">{{'stepperForm.skills.title' | translate}}</h2>
                    <h4>{{'stepperForm.skills.header' | translate}}</h4>
                    <p (click)="createNewRegistrationSkill(newRegistrationFormSkills)" class="cursor-pointer  p-4">+ {{'stepperForm.skills.addSkill' | translate}}</p>
                    <div *ngIf="this.newRegistrationFormSkills.controls.addNewSkill.value" class="inner-card-section new-item">
                        <div class="field-body text-center w-100">
                            <h4>{{'stepperForm.skills.skill' | translate}}</h4>
                            <mat-form-field appearance="outline" class=" mt-4">
                                <mat-label>{{'stepperForm.skills.skill' | translate}}</mat-label>
                                <input type="text"
                                placeholder="Pick one"
                                aria-label="type"
                                matInput
                                formControlName="skillName"
                                [matAutocomplete]="auto">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                    <mat-option *ngFor="let item of filteredSkills | async" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class=" mt-4">
                                <mat-label>{{'stepperForm.language.level' | translate}}</mat-label>
                                <mat-select formControlName="level" matNativeControl required>
                                    <mat-option *ngFor="let item of this.allCVOptionsFromDB.skillLevels" [value]="item">
                                        {{item | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="new-item-nav">
                            <button data-first-save type="reset" (click)="closeCreateNewRegistrationSkill(newRegistrationFormSkills)" class="close">{{'shared.close' | translate}}</button>
                            <button data-first-save (click)="saveNewSkill(newRegistrationFormSkills)" [ngClass]="newRegistrationFormSkills.valid ? 'valid-submit-button' : ''" class="save">{{'shared.save' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="new-registration-navigation" [ngClass]="!this.togleNav ? 'activeNav' : ''">
                    <button mat-button cdkStepperPrevious>{{'shared.back' | translate}}</button>
                    <button data-second-next mat-button
                    (click)="goToNextStepFromSkills()"
                    [ngClass]="this.newRegistrationSkills.length >0 && newRegistrationFormSkills.disabled && !tempnewRegistrationSkills && !tempEditnewRegistrationSkills ? 'valid-submit-button' : ''">{{'shared.next' | translate}}</button>
            </footer>
        </div>
        </form>
    </cdk-step>

    <cdk-step label="{{'stepperForm.summary.label' | translate}}" [optional]="false">
        <div class="mobile-menu cursor-pointer">
            <mat-icon (click)="toggleNavigation()">menu</mat-icon>
        </div>
        <ng-template cdkStepLabel let-value="value" let-selectedI="selectedI">
            <span  class="dot d-flex align-items-center justify-content-center">
                <span *ngIf="selectedI == value || (selectedI != value && !allFormValid())">{{value+1}}</span>
                <mat-icon *ngIf="selectedI != value && allFormValid()">edit</mat-icon>
            </span>
        <span class="lable-text">{{'stepperForm.summary.label' | translate}}</span>
        </ng-template>
        <div class="outer-card-section w-100 inner-form-section container">
            <div class="field is-horizontal inner-card-section">
                <div class="field-body w-100 summary text-center">
                    <h2>{{'stepperForm.summary.title' | translate}}</h2>
                    <section *ngIf="newRegistrationPersonalInfo" class="d-flex justify-content-center">
                        <div class="content w-75">
                            <h3>{{'stepperForm.personalInfo.label' | translate}}</h3>
                            <aside class="d-flex align-items-center flex-column">
                                <p><span>{{'stepperForm.personalInfo.gender' | translate}}: </span>{{newRegistrationPersonalInfo.gender | translate}}</p>
                                <p><span>{{'stepperForm.personalInfo.firstName' | translate}}: </span>{{newRegistrationPersonalInfo.name}}</p>
                                <p><span>{{'stepperForm.personalInfo.lastName' | translate}}: </span>{{newRegistrationPersonalInfo.surname}}</p>
                                <p><span>{{'stepperForm.personalInfo.addTitleP' | translate}}: </span>{{newRegistrationPersonalInfo.title}}</p>
                                <p><span>{{'stepperForm.personalInfo.addTitlePAfterName' | translate}}: </span>{{newRegistrationPersonalInfo.titleAfterName}}</p>
                                <p><span>{{'stepperForm.personalInfo.birthDate' | translate}}: </span>{{newRegistrationPersonalInfo.birthDate | date:'d.M.Y'}}</p>
                                <p><span>{{'stepperForm.personalInfo.email' | translate}}: </span>{{newRegistrationPersonalInfo.email}}</p>
                                <p><span>{{'stepperForm.personalInfo.telephoneNumber' | translate}}: </span>{{newRegistrationPersonalInfo.telephone}}</p>
                                <p><span>{{'stepperForm.personalInfo.streetName' | translate}}: </span>{{newRegistrationPersonalInfo.streetName}}</p>
                                <p><span>{{'stepperForm.personalInfo.streetNumber' | translate}}: </span>{{newRegistrationPersonalInfo.streetNumber}}</p>
                                <p><span>{{'stepperForm.personalInfo.zip' | translate}}: </span>{{newRegistrationPersonalInfo.zip}}</p>
                                <p><span>{{'stepperForm.personalInfo.city' | translate}}: </span>{{newRegistrationPersonalInfo.city}}</p>
                                <p><span>{{'stepperForm.personalInfo.country' | translate}}: </span>{{newRegistrationPersonalInfo.country}}</p>
                            </aside>
                        </div>
                        <div class="edit d-flex align-items-center">
                            <aside>
                                <mat-icon class="ml-2 mr-2 mt-1" (click)="editStepFromSummary('Personal Information')" >edit</mat-icon>
                            </aside>
                        </div>
                    </section>
                    <section *ngIf="newRegistrationHighSchool.length >= 1">
                        <mat-divider></mat-divider>
                        <div class="container d-flex justify-content-center">
                            <div class="content w-75">
                                <h3>{{'stepperForm.highSchool.label' | translate}}</h3>
                                <aside class="d-flex align-items-center flex-column" *ngFor="let school of newRegistrationHighSchool">
                                    <p><span>{{'stepperForm.highSchool.hsNAme' | translate}}: </span>{{school.highSchoolName}}</p>
                                    <p><span>{{'stepperForm.highSchool.hsField' | translate}}: </span>{{school.fieldOfStudy}}</p>
                                    <p><span>{{'stepperForm.personalInfo.city' | translate}}: </span>{{school.city}}</p>
                                    <p><span>{{'stepperForm.personalInfo.country' | translate}}: </span>{{school.country}}</p>
                                    <p><span>{{'stepperForm.highSchool.endDate' | translate}}: </span>{{school.endDate | date:'d.M.Y'}}</p>
                                    <p><span>{{'dashboard.jobOfferDetail.HSAttachment' | translate}}: </span>{{school.candidateAttachment.fileName}}</p>
                                </aside>
                            </div>
                            <div class="edit d-flex align-items-center">
                                <aside>
                                    <mat-icon class="ml-2 mr-2 mt-1" (click)="editStepFromSummary('High scholl education')" >edit</mat-icon>
                                </aside>
                            </div>
                        </div>
                    </section>
                    <section *ngIf="newRegistrationUniversity.length >= 1">
                        <mat-divider></mat-divider>
                        <div class="container d-flex justify-content-center">
                            <div class="content w-75">
                                <h3>{{'stepperForm.university.label' | translate}}</h3>
                                <aside class="d-flex align-items-center flex-column" *ngFor="let school of newRegistrationUniversity">
                                    <p><span>{{'stepperForm.university.uName' | translate}}: </span>{{school.universityName}}</p>
                                    <p><span>{{'stepperForm.university.uField' | translate}}: </span>{{school.fieldOfStudy}}</p>
                                    <p><span>{{'stepperForm.personalInfo.city' | translate}}: </span>{{school.city}}</p>
                                    <p><span>{{'stepperForm.personalInfo.country' | translate}}: </span>{{school.country}}</p>
                                    <p><span>{{'stepperForm.highSchool.startDate' | translate}}: </span>{{school.startDate | date:'d.M.Y'}}</p>
                                    <p><span>{{'stepperForm.highSchool.endDate' | translate}}: </span>{{!school.current ? (school.endDate | date:'d.M.Y') : 'Currently studying'}}</p>
                                    <p><span>{{'stepperForm.university.certificate' | translate}}: </span>{{school.candidateAttachment.fileName}}</p>
                            </aside>
                            </div>
                            <div class="edit d-flex align-items-center">
                                <aside>
                                    <mat-icon class="ml-2 mr-2 mt-1" (click)="editStepFromSummary('University education')" >edit</mat-icon>
                                </aside>
                            </div>
                        </div>
                    </section>
                    <!-- <section *ngIf="newRegistrationCourse.length >= 1">
                        <mat-divider></mat-divider>
                        <div class="container d-flex justify-content-center">
                            <div class="content w-75">
                                <h3>Courses</h3>
                                <aside class="d-flex align-items-center flex-column" *ngFor="let course of newRegistrationCourse">
                                    <p><span>Course: </span>{{course.courseName}}</p>
                                    <p><span>Certificate: </span>{{course.certificate}}</p>
                                    <p><span>End date: </span>{{course.endDate | date:'d.M.Y'}}</p>
                                    <p><span>Course specification: </span>{{course.specification}}</p>
                            </aside>
                            </div>
                            <div class="edit d-flex align-items-center">
                                <aside>
                                    <mat-icon class="ml-2 mr-2 mt-1" (click)="editStepFromSummary('Courses')" >edit</mat-icon>
                                </aside>
                            </div>
                        </div>
                    </section> -->
                    <section *ngIf="newRegistrationProject.length >= 1">
                        <mat-divider></mat-divider>
                        <div class="container d-flex justify-content-center">
                            <div class="content w-75">
                                <h3>{{'stepperForm.projects.label' | translate}}</h3>
                                <aside class="d-flex align-items-center flex-column" *ngFor="let project of newRegistrationProject">
                                    <p><span>{{'stepperForm.projects.title' | translate}}: </span>{{project.title}}</p>
                                    <p><span>{{'stepperForm.projects.position' | translate}}: </span>{{project.position}}</p>
                                    <p><span>{{'stepperForm.projects.duration' | translate}}: </span>{{project.duration}}</p>
                                    <p><span>{{'stepperForm.personalInfo.country' | translate}}: </span>{{project.country}}</p>
                            </aside>
                            </div>
                            <div class="edit d-flex align-items-center">
                                <aside>
                                    <mat-icon class="ml-2 mr-2 mt-1" (click)="editStepFromSummary('Projects')" >edit</mat-icon>
                                </aside>
                            </div>
                        </div>
                    </section>
                    <section *ngIf="newRegistrationLanguages.length >= 1">
                        <mat-divider></mat-divider>
                        <div class="container d-flex justify-content-center">
                            <div class="content w-75">
                                <h3>{{'stepperForm.language.label' | translate}}</h3>
                                <aside class="d-flex align-items-center flex-column" *ngFor="let item of newRegistrationLanguages">
                                    <p><span>{{'stepperForm.language.language' | translate}}: </span>{{item.language}}</p>
                                    <p><span>{{'stepperForm.language.level' | translate}}: </span>{{item.level}}</p>
                            </aside>
                            </div>
                            <div class="edit d-flex align-items-center">
                                <aside>
                                    <mat-icon class="ml-2 mr-2 mt-1" (click)="editStepFromSummary('Languages')" >edit</mat-icon>
                                </aside>
                            </div>
                        </div>
                    </section>
                    <section *ngIf="newRegistrationWorkExperience.length >= 1">
                        <mat-divider></mat-divider>
                        <div class="container d-flex justify-content-center">
                            <div class="content w-75">
                                <h3>{{'stepperForm.workExperiences.label' | translate}}</h3>
                                <aside class="d-flex align-items-center flex-column" *ngFor="let item of newRegistrationWorkExperience">
                                    <p><span>{{'stepperForm.workExperiences.employer' | translate}}: </span>{{item.employer}}</p>
                                    <p><span>{{'stepperForm.workExperiences.position' | translate}}: </span>{{item.position}}</p>
                                    <p><span>{{'stepperForm.personalInfo.city' | translate}}: </span>{{item.city}}</p>
                                    <p><span>{{'stepperForm.personalInfo.country' | translate}}: </span>{{item.country}}</p>
                                    <p><span>{{'stepperForm.highSchool.startDate' | translate}}: </span>{{item.startDate | date:'d.M.Y'}}</p>
                                    <p><span>{{'stepperForm.highSchool.endDate' | translate}}: </span>{{!item.current ? (item.endDate | date:'d.M.Y') : 'Currently working'}}</p>
                            </aside>
                            </div>
                            <div class="edit d-flex align-items-center">
                                <aside>
                                    <mat-icon class="ml-2 mr-2 mt-1" (click)="editStepFromSummary('Work experiense')" >edit</mat-icon>
                                </aside>
                            </div>
                        </div>
                    </section>
                    <section *ngIf="newRegistrationSkills.length >= 1">
                        <mat-divider></mat-divider>
                        <div class="container d-flex justify-content-center">
                            <div class="content w-75">
                                <h3>{{'stepperForm.skills.label' | translate}}</h3>
                                <aside class="d-flex align-items-center flex-column" *ngFor="let skill of newRegistrationSkills">
                                    <p><span>{{'stepperForm.skills.skill' | translate}}: </span>{{skill.skillName}}</p>
                                    <p><span>{{'stepperForm.language.level' | translate}}: </span>{{skill.level | translate}}</p>
                            </aside>
                            </div>
                            <div class="edit d-flex align-items-center">
                                <aside>
                                    <mat-icon class="ml-2 mr-2 mt-1" (click)="editStepFromSummary('Skills')" >edit</mat-icon>
                                </aside>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
            <footer class="new-registration-navigation" [ngClass]="!this.togleNav ? 'activeNav' : ''">
                <button mat-button cdkStepperPrevious>{{'shared.back' | translate}}</button>
                <button mat-button cdkStepperNext type="submit" (click)="sendToAPI()"  [ngClass]="allFormValid() ? 'valid-submit-button' : ''">{{'stepperForm.save' | translate}}</button>
            </footer>
        </div>
    </cdk-step>
</app-stepper>
