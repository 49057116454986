import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { UserFromBE } from '@talentway/shared';
import { JobsService } from '../jobs/jobs.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PasswordResetDialog } from './password-reset/password-reset-dialog';
import { BodyUserDataResponse } from 'src/app/shared/models/responses/body-user-response.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
  loginForm: FormGroup;
  html: '';
  state$: Observable<any>;

  jobOfferId: number;
  redirect: string;
  reactionOnJobOffer = false;
  afterEmailVerification = false;
  urlPath: string;
  token: string | null;
  verificationHash: string | null;
  loginMsg = '';
  cancel: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private jobsService: JobsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog) {
    this.loginForm = this.fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.jobOfferId = Number(this.activatedRoute.snapshot.paramMap.get('jobOfferId'));
    this.redirect = this.activatedRoute.snapshot.queryParams.redirect as string;
    this.urlPath = location.pathname.substring(1);
    if (this.jobOfferId && this.urlPath.includes('joblogin')) {
      this.reactionOnJobOffer = true;
    }

    if(this.authService.afterPasswordReset){
      this.translate.get('login.afterPasswordReset', { value: '' }).subscribe((resp: string) => {
        this.snackBar.open(resp, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      });
    this.authService.afterPasswordReset = false;
    }

    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if(this.token !== null){
      this.verifyEmail(this.token)
    }
  }

  checkLoginStatus(): void{
    if(this.authService.isLoggedIn()){
      this.router.navigate(['']);
    } else {
      if (this.afterEmailVerification) {
        this.router.navigate(['joblogin']);
      } else {
        this.router.navigate(['']);
      }
    }
  }

  login(): void {
    this.authService.login(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value).subscribe(res => {
      if (res) {
        if (this.redirect) {
          this.router.navigate(['/reg'], {});
        } else{
          this.router.navigate([''], {});
        }
      } else {
        this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
          this.cancel = resp;
        });
        let msg;
        this.translate.get('login.unauthorized', { value: '' }).subscribe((resp: string) => {
          msg = resp;
        });
        this.snackBar.open(msg, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      }
    });
  }
  joblogin(): void {

    this.authService.login(this.loginForm.get('email')?.value, this.loginForm.get('password')?.value).subscribe(res => {
      if (res && this.jobOfferId) {
        this.router.navigate(['contact-form/', this.jobOfferId]);
      } else if (res && this.urlPath.includes('joblogin')) {
        this.router.navigate(['/']);
      } else {
        this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
          this.cancel = resp;
        });
        let msg;
        this.translate.get('login.unauthorized', { value: '' }).subscribe((resp: string) => {
          msg = resp;
        });
        this.snackBar.open(msg, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      }
    });
  }

  jobregister(): void {
    this.router.navigate(['jobregistration', this.jobOfferId]);
  }

  forgotPassword(): void {
    const dialogRef = this.dialog.open(PasswordResetDialog, { autoFocus: false });
  }

  verifyEmail(token: string): void{
    this.authService.verifyEmail(token).subscribe(
      res => this.handleVerificationResponse(res),
      error => this.handleVerificationError(error)
    )
  }

  handleVerificationResponse(res: BodyUserDataResponse): void{
    this.translate.get('shared.emailVerified', { value: '' }).subscribe((resp: string) => {
      this.loginMsg = resp;
    });
    this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
      this.cancel = resp;
    });
    this.snackBar.open(this.loginMsg, this.cancel, {
      duration: 3000,
      panelClass: ['snackbar-formater']
    });
    this.afterEmailVerification = true;
    this.checkLoginStatus();
  }

  handleVerificationError(error: string): void{
    if(error === "Email Already Verified"){
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.cancel = resp;
      });
      this.translate.get('login.verificationLinkUsedAlready', { value: '' }).subscribe((resp: string) => {
        this.snackBar.open(resp, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      });
    }else{
      this.translate.get('shared.cancel', { value: '' }).subscribe((resp: string) => {
        this.cancel = resp;
      });
      this.translate.get('login.linkInvalid', { value: '' }).subscribe((resp: string) => {
        this.snackBar.open(resp, this.cancel, {
          duration: 3000,
          panelClass: ['snackbar-formater']
        });
      });
    }
    this.afterEmailVerification = true;
    this.checkLoginStatus();

  }

}
