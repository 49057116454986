import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { FinallFirmRegisterModel } from '../models/firm-register/firm-register-final.model';
import { BodyNullResponse } from '../models/responses/body-null-response.model';
import { BodyUserDataResponse } from '../models/responses/body-user-response.model';
import { UserFromBE } from '@talentway/shared';
import { NotificationService } from './notification.service';


export interface UserType {
  createdAt: string;
  deletedAt: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  password: string;
  session: string;
  tenant: string;
  updatedAt: string;
}
export class User {
  firstName: string;
  lastName: string;
  email: string;
  tenantId: string;
  password: string;
  company: string;
  marketing: boolean;
  privacyPolicy: boolean;
  language: string;
}
@Injectable({providedIn: 'root'})
export class AuthService {

  private unsubscribe: Subscription[] = [];
  currentUser$: Observable<UserFromBE>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserFromBE>;
  isLoadingSubject: BehaviorSubject<boolean>;

  loginURL = 'login';
  logoutURL = 'logout';
  verifyURL = 'verify';
  verifyEmailURL = 'email-verify';
  registerURL = 'register';
  registerCompanyURL = 'register-company';
  requestPasswordResetURL = 'request-password-reset';
  resetPasswordURL = 'password-reset';
  validateResetTokenURL = 'validate-reset-token';
  registerInvitedUserURL = 'register-invited-user';å

  public authSource = new Subject<boolean>();
  authObservable = this.authSource.asObservable();
  private authenticated = false;
  user: UserType;
  userFromBE: UserFromBE;
  afterPasswordReset = false;

  public get currentUserValue(): UserFromBE {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserFromBE) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<any>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  // tslint:disable-next-line:typedef
  private static getByteArray(wordArray: any) {
    const words = wordArray.words;
    const sigBytes = wordArray.sigBytes;

    const u8 = new Uint8Array(sigBytes);
    for (let i = 0; i < sigBytes; i++) {
      // tslint:disable-next-line:no-bitwise
      const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
      u8[i] = byte;
    }

    return u8;
  }
  private getHTTPHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json');
  }

  // tslint:disable-next-line:typedef
  hashPassword(password: string) {
    for (let i = 0; i < 1000; ++i) {
      password = this.byteToHex(AuthService.getByteArray(CryptoJS.SHA1(password)));
    }

    return password;
  }

  private byteToHex(digest: Uint8Array): string {
    let result = '';
    let hexString: string;
    let ult0 = false;
    for (const d of digest) {
      hexString = this.toHex(d);
      if (hexString.length < 2) {
        result += 'a';
        if (hexString.length === 1) {
          ult0 = true;
        }
      } else {
        ult0 = false;
      }
      result += hexString;
    }

    if (ult0) {
      result = result.substring(0, result.length - 2)
        + result.charAt(result.length - 1);
    }

    return result;
  }

  private toHex(d: number): string {
    return (Number(d).toString(16)).slice(-2);
  }

  register(user: User): Observable<boolean> {
    this.isLoadingSubject.next(true);
    const pass = user.password;
    user.password = this.hashPassword(user.password);
    return this.http.post(environment.apiUrl + this.registerURL, user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap((val) => {
        return this.login(user.email, pass);
      }
      ),
      catchError((err) => {
        console.log(err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  registerInvitedUser(user: User, hash: string): Observable<boolean> {
    let queryParams = new HttpParams();
    this.isLoadingSubject.next(true);
    const pass = user.password;
    user.password = this.hashPassword(user.password);
    queryParams = queryParams.append('inviteHash', hash ? hash.toString() : '');
    return this.http.post(environment.apiUrl + this.registerInvitedUserURL, user, {params: queryParams}).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap((val) => {
        return this.login(user.email, pass);
      }
      ),
      catchError((err) => {
        console.log(err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resetPassword(password: string, token: string) {
    const pwd = this.hashPassword(password);

    const data = {
      password: pwd,
      token: token
    };

    let queryParams = new HttpParams();

    queryParams = queryParams.append('password', pwd );
    queryParams = queryParams.append('token', token );
    return this.http.post<any>(environment.apiUrl + this.resetPasswordURL, data, {params: queryParams});

  }

  isLoggedIn():boolean{
    const user = localStorage.getItem('user')
    return user !== null;
  }

  login(email: string, password: string, reaction?: boolean): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.loginUser(email, password, reaction).pipe(
      map((res: BodyUserDataResponse) => {
        this.authenticated = true;
        this.userFromBE = res.body;
        this.currentUserValue = res.body;

        localStorage.setItem('session', res.body.session);
        localStorage.setItem('user', JSON.stringify(this.userFromBE));
        return true;
      }),
      catchError(async () => false)

    );
  }

  loginUser(email: string, passWord: string, reaction?: boolean): Observable<BodyUserDataResponse> {
    let pass;
    if (reaction) {
      pass = passWord;
    } else {
      pass = this.hashPassword(passWord);
    }
    const data = {
      email, password: pass,
    };

    const httpHeaders = this.getHTTPHeaders();

    let queryParams = new HttpParams();
    queryParams = queryParams.append("type", "candidate")

    return this.http.post<BodyUserDataResponse>(environment.apiUrl + this.loginURL,
      data, {headers:httpHeaders, params: queryParams}
    );
  }

  verifyUser(): Observable<any> {
    return this.http.get(environment.apiUrl + this.verifyURL, null);
  }


  verifyEmail(token: string): Observable<BodyUserDataResponse> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('token', token );
    return this.http.post<BodyUserDataResponse>(environment.apiUrl + this.verifyEmailURL, null, {params: queryParams});
  }

  signOut(id?: number): Observable<BodyUserDataResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();

    queryParams = queryParams.append('id', id );
    const data = id;

    return this.http.post<BodyUserDataResponse>(environment.apiUrl + this.logoutURL, data, { headers: httpHeaders, params: queryParams });
  }

  createNewCompany(data: FinallFirmRegisterModel): Observable<any> {
    return this.http.post<any>(environment.apiUrl + this.registerCompanyURL, JSON.stringify(data));
  }


  requestPasswordReset(email: string): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('email', email );
    const data = email;
    return this.http.post<any>(environment.apiUrl + this.requestPasswordResetURL, data, {params:queryParams});
  }

  toLoginAfterPasswordReset(): void{
    this.afterPasswordReset = true;
    this.router.navigate(['']);
  }

  validateResetToken(token: string): Observable<BodyUserDataResponse>{
    let queryParams = new HttpParams();

    queryParams = queryParams.append('token', token );
    const data = {
      token: token
    };
    return this.http.post<any>(environment.apiUrl + this.validateResetTokenURL, data, {params: queryParams});
  }

}
