<div class="background-img d-flex flex-center flex-column flex-column-fluid">
  <mat-card class="container login-container p-4">
    <form [formGroup]="passwordResetForm">
      <h3>{{'login.newPassword' | translate}}</h3>
      <mat-divider></mat-divider>
      <div *ngIf="loaded" class="d-flex justify-content-between option-menu">
        <div *ngIf="!tokenValid" class="alert alert-danger d-flex flex-column w-100  mt-3">
          <h3>{{ errorMsg }}</h3>
        </div>
        <div *ngIf="tokenValid" class="d-flex flex-column w-100 mt-3">
          <div class="d-flex flex-column">
            <mat-form-field appearance="outline">
              <mat-label>{{'stepperForm.personalInfo.email' | translate}}</mat-label>
              <input readonly matInput placeholder="ex. example@gmail.com" formControlName="email" autocomplete="email">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{'shared.password' | translate}}</mat-label>
              <input matInput placeholder="Placeholder" formControlName="password" type="password" autocomplete="new-password">
            </mat-form-field>
            <div *ngIf="passwordResetForm.get('password')?.touched && passwordResetForm.get('password')?.invalid"
            class="alert alert-danger">
              <div *ngIf="passwordResetForm.get('password')?.errors?.required">{{'shared.passwordErrorRequired' | translate}}</div>
              <div *ngIf="passwordResetForm.get('password')?.errors?.minlength">{{'shared.passwordErrorMinlength' | translate}}</div>
            </div>

            <mat-form-field appearance="outline">
              <mat-label>{{'shared.verifyPassword' | translate}}</mat-label>
              <input matInput placeholder="Placeholder" formControlName="verifyPassword" type="password" autocomplete="new-password">
            </mat-form-field>
            <div
              *ngIf="passwordResetForm.get('verifyPassword')?.touched && passwordResetForm.get('verifyPassword')?.invalid" class="alert alert-danger">
              <div *ngIf="passwordResetForm.get('verifyPassword')?.errors?.required">{{'shared.verifyPasswordErrorRequired' | translate}}</div>
              <div *ngIf="passwordResetForm.get('verifyPassword')?.errors?.mustMatch">{{'shared.verifyPasswordErrorMatch' | translate}}</div>
            </div>

            <button [disabled]="!passwordResetForm.touched || passwordResetForm.invalid" mat-raised-button color="primary"
            (click)="submit()">{{'shared.submit' | translate}}</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>