import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CustomValidators } from 'src/app/shared/validators/password';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  passwordResetForm: FormGroup;
  customValidators = {
    passwordFormControl: new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]),
    verifyPasswordFormControl: new FormControl('', [
      Validators.required,
    ]
    ),
    emailFormControl: new FormControl('', [
      Validators.required,
      Validators.email
    ]
    ),
  };

  token: string;
  tokenValid: boolean;
  linkInvalid: boolean;
  email: string;
  loaded = false;

  errorMsg: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService
  ) {
    this.passwordResetForm = this.fb.group({
      email: this.customValidators.emailFormControl,
      password: this.customValidators.passwordFormControl,
      verifyPassword: this.customValidators.verifyPasswordFormControl,
    }, {
      validator: CustomValidators.mustMatch('password', 'verifyPassword')
    });
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    // this.email = String(this.activatedRoute.snapshot.queryParamMap.get('email'));
    if (this.token === null) {
        this.translate.get('login.linkInvalid', { value: '' }).subscribe((resp: string) => {
          this.errorMsg = resp;
        });
        this.loaded = true;
    } else {
      this.checkTokenValidity().then(() =>{
        this.loaded = true;
      })

    }

  }

  checkTokenValidity() {
    return new Promise((resolve, reject) => {
      this.authService.validateResetToken(this.token).subscribe(
        res => {
          this.tokenValid = res.status === 200;
          this.passwordResetForm.get('email')?.setValue(res.body);
          resolve(res);
          return(res);
        },
        error => {
          if(error === "Token Used Already"){
            this.translate.get('login.linkUsedAlready', { value: '' }).subscribe((resp: string) => {
              this.errorMsg = resp;
            });
          }
          if(error === "Invalid Token"){
            this.translate.get('login.linkInvalid', { value: '' }).subscribe((resp: string) => {
              this.errorMsg = resp;
            });
          }
          this.linkInvalid = true;
          resolve(error);
          return(error);
        }
      )
    })
  }

  submit(): void {
    if (this.passwordResetForm.valid) {
      const password = this.passwordResetForm.get('password')?.value;
      this.authService.resetPassword(password, this.token).subscribe(
        res => { this.authService.toLoginAfterPasswordReset() },
        error => {
          this.snackBar.open("An error occured.", 'CANCEL', {
            duration: 3000,
          })
        }
      )
    }
  }
}
