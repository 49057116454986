import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { BodyJobsFromBEResponse } from 'src/app/shared/models/responses/body-jobs-response.model';
import { BodyStringResponse } from 'src/app/shared/models/responses/body-string-response.model';
import { PersonalDataAfterContactForm } from 'src/app/shared/models/jobs/contact-form.model';
import { BodyNullResponse } from 'src/app/shared/models/responses/body-null-response.model';
import { BodyPublicUserDataResponse } from 'src/app/shared/models/jobs/responses/body-publick-user-response.model';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { PersonalData } from 'src/app/shared/models/registration-questions-personal-data.model';
import { BodyUserDataResponse } from 'src/app/shared/models/responses/body-user-response.model';
import { UserType } from 'src/app/shared/services/auth.service';
import * as CryptoJS from 'crypto-js';
import { BodyAttachmentResponse } from 'src/app/shared/models/responses/body-attachment-response.model';
import { UserFromBE } from '@talentway/shared';
import { ApplyToJobUser } from 'src/app/shared/models/dashboard/apply-to-job-user-from-be';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  private unsubscribe: Subscription[] = [];
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  public authSource = new Subject<boolean>();
  authObservable = this.authSource.asObservable();
  private authenticated = false;
  user: UserType;
  publickUser: PersonalData;

  // JOBS SUBPAGE
  jobloginURL = 'job-login';
  joblogoutURL = 'job-logout';
  getJobsURL = 'get-jobs';
  getJobOfferByIdURL = 'get-job-offer-id';
  getJobOfferByIdPublicURL = 'get-job-offer-id-public';
  cvUploadFile = 'upload-file';
  applayToJobURL = 'apply-to-job';

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }


  constructor(
    private http: HttpClient
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<any>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  // tslint:disable-next-line:typedef
  private static getByteArray(wordArray: any) {
    const words = wordArray.words;
    const sigBytes = wordArray.sigBytes;

    const u8 = new Uint8Array(sigBytes);
    for (let i = 0; i < sigBytes; i++) {
      // tslint:disable-next-line:no-bitwise
      const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
      u8[i] = byte;
    }

    return u8;
  }

  private getHTTPHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json');
  }

  // tslint:disable-next-line:typedef
  hashPassword(password: string) {
    for (let i = 0; i < 1000; ++i) {
      password = this.byteToHex(JobsService.getByteArray(CryptoJS.SHA1(password)));
    }

    return password;
  }

  private byteToHex(digest: Uint8Array): string {
    let result = '';
    let hexString: string;
    let ult0 = false;
    for (const d of digest) {
      hexString = this.toHex(d);
      if (hexString.length < 2) {
        result += 'a';
        if (hexString.length === 1) {
          ult0 = true;
        }
      } else {
        ult0 = false;
      }
      result += hexString;
    }

    if (ult0) {
      result = result.substring(0, result.length - 2)
        + result.charAt(result.length - 1);
    }

    return result;
  }

  private toHex(d: number): string {
    return (Number(d).toString(16)).slice(-2);
  }

  getJobs(limit?: string, offset?: string, salary?: string,
    tenantID?: string, dateTo?: string, dateFrom?: string, filter?: string, cityFilter?: string): Observable<any>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append('limit', limit ? limit : '0');
    queryParams = queryParams.append('offset', offset ? offset : '0');
    queryParams = queryParams.append('salary', salary ? salary : '');
    queryParams = queryParams.append('tenantID', tenantID ? tenantID : '');
    queryParams = queryParams.append('dateTo', dateTo ? dateTo : '');
    queryParams = queryParams.append('dateFrom', dateFrom ? dateFrom : '');
    queryParams = queryParams.append('filter', filter ? filter : '');
    queryParams = queryParams.append('cityFilter', cityFilter ? cityFilter : '');

    return this.http.get<any>(environment.apiUrl + this.getJobsURL, {params: queryParams});
  }

  getJobOfferById(id?: number): Observable<BodyJobsFromBEResponse> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');

    return this.http.get<BodyJobsFromBEResponse>(environment.apiUrl + this.getJobOfferByIdURL, {
      params: queryParams
    });
  }

  getJobOfferByIdPublic(id?: number): Observable<BodyJobsFromBEResponse> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', id ? id : '');

    return this.http.get<BodyJobsFromBEResponse>(environment.apiUrl + this.getJobOfferByIdPublicURL, {
      params: queryParams
    });
  }

  sendCVAttachment(data: Blob, fileTYpe: string): Observable<BodyStringResponse> {
    const httpHeaders = this.getHTTPHeaders();
    let queryParams = new HttpParams();
    queryParams = queryParams.append('filetype', fileTYpe);

    return this.http.post<BodyStringResponse>(environment.apiUrl +
      this.cvUploadFile, data, { headers: httpHeaders, params: queryParams });
  }

  applyToJob(jobID: number, data: ApplyToJobUser): Observable<BodyStringResponse> {

    const httpHeaders = this.getHTTPHeaders();

    let queryParams = new HttpParams();
    queryParams = queryParams.append('jobOfferID', jobID);

    return this.http.post<BodyNullResponse>(environment.apiUrl +
      this.applayToJobURL, JSON.stringify(data), { headers: httpHeaders, params: queryParams });
  }

}
